import React from "react";
import './App.css';
import NavbarComponent from "./components/NavbarComponent";
import { Outlet } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';



function App() {
 
  return (
    <div className="App">
      <NavbarComponent />
      <Outlet />
    </div>
  );
}

export default App;