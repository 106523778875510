import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { baseURL } from '../../utils/config';

function ListElements() {

    const [data, setData] = React.useState(null);
    let navigate = useNavigate();
    let params = useParams();

    //useEffect is used to check the url that is in the browser. I there is a query it will automatically
    //make the search and update the list. This enables users to send links between each other and for
    //the back and forth arrows to work properly. The browser only rembers what adress it visited last.
    React.useEffect(() => {
        fetch(`${baseURL}/products?` + params.query, {
            headers: {
                'Authorization': localStorage.getItem("token")
            }
        })
            .then((res) => {
                return res.json()
            })
            .then((data) => {
                console.log(data.data)
                setData(data.data)
            }).catch((error) => {
                console.error("Error fetching products:", error);
            });
    }, [params.query]);


    //This handles the search when we try to search for an item using the search bar above the list of elements.
    function handleSubmit(event) {
        event.preventDefault(); // prevent the form from submitting
        const formData = new FormData(event.target);
        const name = formData.get("name");
        const query = "name_like=" + name;
        fetch(`${baseURL}/products?` + query, {
            headers: {
                'Authorization': localStorage.getItem("token")
            }
        })
            .then((res) => {
                console.log(res)
                return res.json()
            })
            .then((data) => {
                setData(data.data)
                console.log(data.data)
            });
        navigate("/products/" + query)
    }

    //Navigates the user to /add_product page when that button is clicked.
    function toAddProduct(event) {
        event.preventDefault(); // prevent the form from submitting
        navigate("/add_product")
    }

    //This is the table that show the elements in a list.
    function Table({ data }) {
        return (
            <table className="table">
                <thead>
                    <tr>
                        <th colSpan="1" className="text-center">Name</th>
                        <th colSpan="1" className="text-center">Description</th>
                        <th colSpan="1" className="text-center">Sell price</th>
                        <th colSpan="1" className="text-center">Price</th>
                        <th colSpan="1" className="text-center">Active</th>
                        <th colSpan="1" className="text-center">Edit</th>
                    </tr>
                </thead>
                <tbody>
                    {!data ? (
                        <tr key="loading">
                            <td colSpan="3" className="text-center">Make a search!</td>
                        </tr>
                    ) : (
                        data.map((element) => (
                            <tr key={element.productId}>
                                <td><img src={"https://trazedev.se/" + element.thumbnail} alt="logo" height="60"></img></td>
                                <td><a href={`/products/id/${element.productId}`}>{element.name}</a></td>
                                <td>{element.description}</td>
                                <td>{element.sell_price}</td>
                                <td>{element.is_active}</td>
                                <td><a href={`/edit/products/${element.productId}`}>Edit</a></td>
                            </tr>
                        ))
                    )}
                </tbody>
            </table>
        );
    }

    return (
        <div className="container">
            <div className="container">
                <div className="mb-3 row g-3">
                    <form onSubmit={handleSubmit} noValidate className="col-auto">
                        <div className="input-group">
                            <input type="text" id="name" name="name" className="form-control" placeholder="Product Name" style={{ maxWidth: "200px" }} />
                            <button type="submit" name="submitType" value="search" className="btn btn-primary">Submit</button>
                        </div>
                    </form>
                    <div className="col-auto ms-auto">
                        <form onSubmit={toAddProduct} noValidate className="col-auto">
                            <button type="submit" name="submitType" value="addProduct" className="btn btn-primary">Add Product</button>
                        </form>
                    </div>
                </div>
            </div>
            <Table data={data} />
        </div>
    )
}

export default ListElements;


