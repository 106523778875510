import React, { useState } from "react";
import './AddProduct.css';
import { useNavigate } from "react-router-dom";
import { baseURL } from '../../utils/config';
import isJsonValid from '../../utils/isJsonValid';


function AddProduct() {
    let navigate = useNavigate();

    //stores the state of the form before it has been submited.
    const [formData, setFormData] = useState({
        name: "",
        description: "",
        specification: "",
        sell_price: "",
        category_id: "",
    });

    //This makes sure that the state of the form updates as the user makes inputs to the form.
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };


    const handleSubmit = (e) => {
        e.preventDefault();

        
        const form = document.querySelector('.needs-validation');

        //This checks if the user inputed everything needed to the form
        if (form.checkValidity() === false) {
            form.classList.add('was-validated');
            return;
        }

        //This checks if the Json input is valid and gives the user an alert otherwise.
        if (!isJsonValid(formData.specification)) {
            // JSON format is not valid
            alert("Specification field should be in valid JSON format");
            return;
        }

        // Convert form data to JSON
        const jsonData = JSON.stringify(formData);

        // Send jsonData to backend server
        fetch(`${baseURL}/products`, { 
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                'Authorization': localStorage.getItem("token")
            },
            body: jsonData
        })
            .then((response) => {
                if (response.ok) {
                    console.log("Data successfully stored!");
                    //Reset form fields after successful submission
                    setFormData({
                        name: "",
                        description: "",
                        specification: "",
                        sell_price: "",
                        category_id: ""
                    });
                    return response.json();
                } else {
                    console.error("Failed to store data.");
                }
            })
            .then((data) => {
                // Navigate to the product page using the product ID
                console.log(data)
                navigate(`/products/id/${data.product_id}`);
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    return (
        <div className="App">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-sm-6">
                        <form className="needs-validation" noValidate onSubmit={handleSubmit}>
                            <div className="row">
                                <div className="col-sm-12 mb-3">
                                    <label htmlFor="name" className="form-label">Name:</label>
                                    <textarea id="name" name="name" value={formData.name} onChange={handleChange} className={`form-control`} required />
                                    <div className="invalid-feedback">
                                        Need to enter a name
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12 mb-3">
                                    <label htmlFor="description" className="form-label">Description:</label>
                                    <textarea id="description" name="description" value={formData.description} onChange={handleChange} className={`form-control `} required />
                                    <div className="invalid-feedback">
                                        Need to enter a description
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12 mb-3">
                                    <label htmlFor="specification" className="form-label">Specification:</label>
                                    <textarea id="specification" name="specification" value={formData.specification} onChange={handleChange} className={`form-control`} required />
                                    <div className="invalid-feedback">
                                        Need to enter a specification
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12 mb-3">
                                    <label htmlFor="sell_price" className="form-label">Sell Price:</label>
                                    <input type="number" id="sell_price" name="sell_price" value={formData.sell_price} onChange={handleChange} className={`form-control`} required />
                                    <div className="invalid-feedback">
                                        Need to enter a sell price
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12 mb-3">
                                    <label htmlFor="category_id" className="form-label">Category ID:</label>
                                    <input type="number" id="category_id" name="category_id" value={formData.category_id} onChange={handleChange} className={`form-control`} required />
                                    <div className="invalid-feedback">
                                        Need to enter a category ID
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-12">
                                    <button type="submit" className="btn btn-primary">Submit</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddProduct;