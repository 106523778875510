import React, { useState } from 'react';
import { baseURL } from '../../utils/config';
import { useParams } from "react-router-dom";

const ZipFileUpload = ({ updateProduct, updateIndex }) => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    let params = useParams();

    const handleFileChange = (e) => {
        setSelectedFile(e.target.files[0]);
    };

    const handleUpload = () => {
        if (!selectedFile) {
            setErrorMessage('Please select a file to upload.');
            return;
        }

        const formData = new FormData();
        formData.append('file', selectedFile); // Change 'image' to 'zipFile' to match the server-side handling

        fetch(`${baseURL}/upload/media/` + params.product_id, {
            method: 'POST',
            headers: {
                'Authorization': localStorage.getItem("token")
            },
            body: formData
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setSuccessMessage(data.message); // Assuming server responds with a message
                setErrorMessage(''); // Clear any previous error message

                // Fetch and set the updated product data after successful upload
                fetch(`${baseURL}/products/` + params.product_id, {
                    headers: {
                        'Authorization': localStorage.getItem("token")
                    }
                })
                    .then(res => res.json())
                    .then(data => {
                        console.log("Product updated after upload:", data);
                        updateProduct(data.data); // Update product state with the new data, including the updated list of images
                        updateIndex(prevIndex => Math.min(prevIndex, data.data.media.images.length - 1));
                    })
                    .catch(error => {
                        console.error("Error fetching updated product:", error);
                    });
            })
            .catch(error => {
                console.error('Error uploading zip file:', error);
                setErrorMessage('Error uploading zip file. Please try again.'); // Set error message
            });
    };

    return (
        <div>
            <input type="file" accept=".zip" onChange={handleFileChange} />
            <button onClick={handleUpload}>Upload ZIP File</button>
            {successMessage && <p>{successMessage}</p>} {/* Display success message if available */}
            {errorMessage && <p>{errorMessage}</p>} {/* Display error message if available */}
        </div>
    );
};

export default ZipFileUpload;
