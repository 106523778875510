import React from "react";
import { useParams } from "react-router-dom";
import { baseURL } from '../../utils/config';
import ZipFileUpload from "./ZipFileUpload";
import { Carousel } from 'react-bootstrap';


function ViewSingleProduct() {
    let params = useParams();
    const [product, setProduct] = React.useState({ media: { images: [] } });
    const [currentIndex, setCurrentIndex] = React.useState(0); // State to keep track of current index

    // Define a function to update the product state
    const updateProduct = (newProduct) => {
        setProduct(newProduct);
    };
    const updateIndex = (newIndex) => {
        setCurrentIndex(newIndex);
    };

    React.useEffect(() => {
        fetch(`${baseURL}/products/` + params.product_id, {
            headers: {
                'Authorization': localStorage.getItem("token")
            }
        })
            .then((res) => {
                console.log(res)
                return res.json()
            })
            .then((data) => {
                console.log(data);
                setProduct(data.data);
            })
            .catch((error) => {
                console.error("Error fetching product:", error);
            });
    }, [params.product_id]);

    const handleDeleteImage = (index) => {
        let jsonData = { "media_rm_list": [product.media.image_ids[index]] };
        jsonData = JSON.stringify(jsonData);

        fetch(`${baseURL}/remove/media`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem("token")
            },
            body: jsonData
        })
            .then(response => {
                if (!response.ok) {
                    console.log(`Server returned status ${response.status}: ${response.statusText}`);
                    return response.json().then(data => {
                        throw new Error(data.message);
                    });
                }

                // Fetch and set the product again
                fetch(`${baseURL}/products/` + params.product_id, {
                    headers: {
                        'Authorization': localStorage.getItem("token")
                    }
                })
                    .then(res => res.json())
                    .then(data => {
                        console.log("Product updated after image deletion:", data);
                        setProduct(data.data);
                        // Adjust currentIndex if necessary after deletion
                        setCurrentIndex(prevIndex => Math.min(prevIndex, data.data.media.images.length - 1));
                    })
                    .catch(error => {
                        console.error("Error fetching updated product:", error);
                    });
            })
            .catch(error => {
                console.error('Error deleting image:', error);
            });
    };

    return (
        <div>
            {!product ? (
                <h1>Loading...</h1>
            ) : (
                <div>
                    <h2>{product.name}</h2>
                    <p>Description: {product.description}</p>
                    <p>Sell Price: {product.sell_price}</p>
                    <p>Specifications: {product.specification}</p>

                    <div className="container">
                        <div className="row justify-content-center align-items-start">
                            <div className="col-md-6 flex-column justify-content-center align-items-center" style={{ backgroundColor: '#f0f0f0' }}>
                                <h3>Images:</h3>
                                {product.media.images.length > 0 ? (
                                    <Carousel fade={false} interval={null} activeIndex={currentIndex} onSelect={(index) => setCurrentIndex(index)}>
                                        {product.media.images.map((image, index) => (
                                            <Carousel.Item key={index}>
                                                <div style={{ position: 'relative', display: 'inline-block' }}>
                                                    <img
                                                        className="d-block w-100"
                                                        src={"https://trazedev.se/" + image}
                                                        alt={"Product image " + (index + 1)}
                                                        style={{ maxHeight: "300px", objectFit: "contain" }}
                                                    />
                                                    <button
                                                        className="btn btn-danger delete-button"
                                                        style={{ position: 'absolute', top: '10px', right: '10px' }}
                                                        onClick={() => handleDeleteImage(index)} // Assuming handleDeleteImage is your delete handler function
                                                    >
                                                        Delete
                                                    </button>
                                                </div>
                                            </Carousel.Item>
                                        ))}
                                    </Carousel>
                                ) : (
                                    <p>No images available</p>
                                )}

                            </div>
                            <div className="col-md-6 d-flex flex-column justify-content-center align-items-center" style={{ backgroundColor: '#f0f0f0' }}>
                                <h3>Thumbnail</h3>
                                <img
                                    className="d-block"
                                    src={"https://trazedev.se/" + product.media.thumbnail}
                                    alt={"Product thumbnail"}
                                    style={{ height: "300px" }} // Set max height and auto width
                                />
                            </div>
                        </div>
                    </div>
                    <ZipFileUpload updateProduct={updateProduct} updateIndex={updateIndex} />
                </div>
            )}
        </div>
    );
}

export default ViewSingleProduct;